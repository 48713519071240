<template lang='pug'>
v-container
    v-row
        v-col
            span.brand--text.text-h3 Overview
    v-row
        v-col.d-flex.flex-wrap
            CategoryCard.mr-2.mb-2(v-for='category in trainingCategories', :key='category.name', :name='category.name', :color='category.color', :progress='category.completed', :icon='category.icon', :total='getTotalCategoryItems(category.id)', :completed='getCategoryCompletion(category.id)', :loading='fetchingResources', :categoryId='category.id', :description='category.description')
    v-row
        v-col
            span.brand--text.text-h3 Progress
    v-row
        v-col.d-flex.flex-shrink-1.flex-grow-0
            v-progress-circular(:value='percentComplete', color='brand', size='200', width='12')
                .totalPercent
                    v-skeleton-loader(:loading='fetchingResources', type='text', light, width='100')
                        template(v-slot:default)
                                span.text-h2.brand--text {{ percentComplete }}%
        v-col
            span.brand--text.text-h5 You've viewed {{ totalCompleted }} of {{ totalItems }} training items!

    v-row
        v-col
            span.brand--text.text-h3 Browse Resources
    v-row
        v-col
            TrainingItems(@trainingCompleted='getCompletionRecords')
</template>

<script>

import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed';
import CategoryCard from '@/components/portal/trainingcategorycard';
import TrainingItems from '@/components/portal/trainingitems';

export default {
    components: {
        VuePdfEmbed,
        CategoryCard,
        TrainingItems
    },
    data () {
        return {
            fetchingResources: false,
            options: {
                itemsPerPage: -1
            },
            headers: [
                {
                    text: 'Application',
                    value: 'name',
                    align: 'left',
                    sortable: true,
                },
                { 
                    text: 'Video Training',  
                    value: 'videoId',
                    sortable: false,
                },
                { 
                    text: 'Guide', 
                    value: 'guide',
                    sortable: false,
                },
                {
                    text: 'Link',
                    value: 'docsLink',
                    sortable: false
                }
            ],
            trainingResources: [],
            trainingCategories: [],
            completionRecords: []
        };
    },
    computed: {
        activeResources () {
            return this.trainingResources.filter(resource => resource.trainingCategoryId);
        },
        totalItems () {
            return this.activeResources.length;
        },
        totalCompleted () {
            let total = 0;
            for (const item of this.activeResources) {
                if (this.isViewed(item.id)) {
                    total += 1;
                }
            }
            return total;
        },
        percentComplete () {
            const percent = this.totalCompleted / this.totalItems * 100;
            return percent.toFixed(0);
        },
        currentUser () {
            return this.$store.state.fullUser;
        },
    },
    methods: {
        async fetchResources () {
            this.fetchingResources = true;
            const results = await this.post('getTrainingResources');
            if (results.err) {
                return;
            }
            const {categories, resources} = results;
            this.trainingResources = resources;
            this.trainingCategories = categories;
            await this.getCompletionRecords();
            this.fetchingResources = false;
        },
        async getCompletionRecords () {
            const results = await this.post('getCompletionRecords', {
                username: this.currentUser.username
            });
            this.completionRecords = results;
            for (const resource of this.trainingResources) {
                const category = this.trainingCategories.find(cat => cat.id === resource.resourceCategoryId);
                if (category) {
                    if (this.isViewed(resource.id)) {
                        category.completed += 1;
                    }
                }
            }
        },
        isViewed (resourceId) {
            return Boolean(this.completionRecords.find(record => record.trainingResourceId === resourceId));
        },
        getTotalCategoryItems (categoryId) {
            return this.activeResources.filter(item => item.trainingCategoryId === categoryId).length;
        },
        getCategoryCompletion (categoryId) {
            const categoryItems = this.activeResources.filter(item => item.trainingCategoryId === categoryId);
            let complete = 0;
            for (const item of categoryItems) {
                if (this.isViewed(item.id)) {
                    complete += 1;
                }
            }
            return complete;
        }
    },
    mounted () {
        this.fetchResources();
    }
};

</script>
<style lang='scss' scoped>
a {
    border-bottom: none;
    color:#009EDA;
}
.title  {
    border-bottom: none;
    color:White;
    background-color:#00355C;
    font-family: Tahoma;
}
</style>
<style>
.totalPercent {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    width: 200px;
    height: 200px;
    .v-skeleton-loader__text {
        width: 200px;
        height: 64px;
    }
}
</style>
