<template lang='pug'>
v-card(:color='color', width='350', @click='goToTrainingPage', dark)
    v-card-title.noWrap
        span {{ name }}
    v-card-text
        v-row
            v-col.noWrap
                span(:title='description') {{ description }}
        template(v-if='!isPreview')
            v-row
                v-col
                    v-progress-linear(:value='percentComplete', :indeterminate='loading')
            v-row
                v-col
                    span {{ completed }} of {{ total }} Viewed
    v-card-actions
        v-spacer
        slot(name='cardActionButtons')
</template>

<script>

export default {
    props: {
        name: {
            type: String,
            default () {
                return '';
            }
        },
        categoryId: {
            type: Number,
            default () {
                return 0;
            }
        },
        color: {
            type: String,
            default () {
                return 'blue';
            }
        },
        icon: {
            type: String,
            default () {
                return '';
            }
        },
        total: {
            type: Number,
            default () {
                return 0;
            }
        },
        completed: {
            type: Number,
            default () {
                return 0;
            }
        },
        loading: {
            type: Boolean,
            default () {
                return false;
            }
        },
        description: {
            type: String,
            default () {
                return '';
            }
        },
        isPreview: {
            type: Boolean,
            default () {
                return false;
            }
        }
    },
    data () {
        return {
        };
    },
    computed: {
        percentComplete () {
            return this.completed / this.total * 100;
        }
    },
    methods: {
        goToTrainingPage () {
            if (this.isPreview) {
                return;
            }
            this.$router.push({
                path: '/customer/resources/category',
                query: {
                    categoryId: this.categoryId
                }
            });
        }
    },
    watch: {
    },
    mounted () {
    }
};

</script>
<style>
.noWrap {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
</style>
